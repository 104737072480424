<ng-container *ngIf="!filterDefParams.quickFilter">
  <h4 *ngIf="filter.label" class="title">{{ filter.label }}</h4>
  <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
</ng-container>

<ng-container *ngIf="filterDefParams.quickFilter">
  <cog-quick-filter
    [cogDataId]="filter.label"
    [hideApplyButton]="autoApply"
    [hideButtons]="hideButtons"
    [inheritDataId]="true"
    [label]="filter.label"
    [preLabel]="preLabel"
    [note]="filter.note"
    [filterTags]="filter.tagValues$ | async"
    [lockedMessage]="filter.lockedMessage"
    [noClear]="noClear"
    [formStyle]="formStyle"
    [hasMenu]="showMenu"
    (opened)="filterClicked.emit($event)"
    (applied)="applyFilters()"
    (cleared)="clearFilters()"
    (menuOpened)="menuOpened()"
    (menuClose)="handleMenuClose()"
    [xPosition]="filterDefParams.quickFilterPositionX">
    <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
  </cog-quick-filter>
</ng-container>

<ng-template #filterTemplate>
  <mat-form-field *ngIf="asyncSearch || filterValues.length > filterValuesSearchThreshold"
    class="cog-value-property-filter-search">
    <input cogDataId="search"
      [attr.aria-label]="intlSvc.search"
      [inheritDataId]="true"
      type="text"
      placeholder="{{intlSvc.search}}"
      matInput
      [formControl]="stringSearchControl">
  </mat-form-field>

  <ng-container *ngIf="asyncSearch && (displayedValues$ | async); let searchResult">
    <div *ngIf="searchResult.length && searchTotal" class="cog-value-property-search-result-title">
      {{ intlSvc.dataFilters.searchResult(searchResult.length, searchTotal)}}
    </div>
    <div *ngIf="!searchResult.length && stringSearchControl.value" class="cog-value-property-search-result-title">
      {{intlSvc.dataFilters.zeroResult}}
    </div>
  </ng-container>

  <div class="cog-value-property-filter-list">
    <div *ngIf="allowMultiple && allowSelectAll" (click)="handleSelectAllCheckboxChange($event)">
      <mat-checkbox
        cogDataId="select-unselect-all-checkbox"
        (click)="$event.preventDefault()"
        [checked]="selectAllCheckboxValue"
        class="select-unselect-all">
        {{(selectAllCheckboxValue ? 'unselectAll' : 'selectAll') | translate}}
      </mat-checkbox>
      <mat-divider></mat-divider>
    </div>

    <mat-selection-list
      cogDataId="list"
      [inheritDataId]="true"
      [multiple]="allowMultiple"
      color="primary"
      #filterOptions>
      <mat-list-option class="cog-value-property-filter-list-option"
        [cogDataId]="item.label"
        [inheritDataId]="true"
        checkboxPosition="before"
        role="menuitem"
        *ngFor="let item of displayedValues$ | async"
        [value]="item"
        [cogScrollIntoView]="isMenuOpened ? isFirstItemSelected(item) : false"
        [selected]="selectionModel.isSelected(item)"
        (click)="isSelectionDisabled(item) ?
          ($event.preventDefault() && $event.stopPropagation()) : toggleSelection(item)"
        [disabled]="isSelectionDisabled(item)"
        [class.cog-property-filter-value-has-tooltip]="!!getTooltipText(item)"
        [class.cog-property-filter-value-single-line]="!item.hintText">
        <cog-icon mat-list-icon [shape]="item.icon" [size]="item.iconSize || 'md'" *ngIf="item.icon"></cog-icon>
        <cog-legend-indicator mat-list-icon *ngIf="item.labelLegend" [legend]="item.labelLegend"></cog-legend-indicator>
        <div mat-line [matTooltipClass]="item.customTooltip ? 'pre-line' : null"
          [matTooltip]="getTooltipText(item)">
          {{item.label}}
        </div>
        <div mat-line *ngIf="item.hintText">{{item.hintText}}</div>
        <cog-icon class="cog-value-property-filter-check"
          *ngIf="!allowMultiple && selectionModel.isSelected(item)"
          shape="check">
        </cog-icon>
      </mat-list-option>

      <mat-option
        *ngIf="showActionButton"
        class="cog-value-property-filter-list-option sticky-option bottom"
        checkboxPosition="before"
        role="menuitem"
        cogDataId="action-button"
        (click)="buttonAction()">
        {{ actionButtonLabel }}
      </mat-option>
    </mat-selection-list>
  </div>
</ng-template>
