import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const vaultClusterAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.isVaultCluster
  && getConfigByKey(ctx.irisContext, 'vault.features.allow', true);

export const VaultClusterConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'vault-cluster.**',
      url: '/vault-cluster',
      loadChildren: () => import('./vault-cluster.module').then(m => m.VaultClusterModule),
    },
  ],
  allClusterMap: {
    'vault-cluster.activities': false,
    'vault-cluster.clusters': false,
    'vault-cluster.policies': false,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'vault-cluster.activities': vaultClusterAccess,
      'vault-cluster.clusters': vaultClusterAccess,
      'vault-cluster.policies': vaultClusterAccess,
    };
  }
};
