import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const policyViewAccess = (ctx: StateAccessContext) =>
  ctx.PROTECTION_POLICY_VIEW && heliosAndOnPremAccessCheck(ctx) && getConfigByKey(ctx.irisContext, 'policies.features.allow', true);

const policyModifyAccess = (ctx: StateAccessContext) =>
  ctx.PROTECTION_POLICY_MODIFY && heliosAndOnPremAccessCheck(ctx);

const heliosAndOnPremAccessCheck = (ctx: StateAccessContext) => {
  if (ctx.isHelios) {
    if (ctx.clusterInfo?._allCluster) {
      return ctx.FEATURE_FLAGS.ngPolicyHelios || ctx.clusterInfo?._serviceType === 'dms';
    }

    // Else for single cluster make sure ngPolicy is available.
    return ctx.FEATURE_FLAGS.ngPolicy;
  }
  return ctx.FEATURE_FLAGS.ngPolicy;
};


export const ProtectionPolicyConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'protection-policy.**',
      url: '/protection-policy',
      loadChildren: () => import('./protection-policy.module').then(m => m.ProtectionPolicyModule)
    },
  ],

  allClusterMap: {
    'protection-policy': true,
    'protection-policy.policies': true,
    'protection-policy.build': true,
    'protection-policy.details': {
      singleClusterState: 'protection-policy.details',
      allClustersState: 'protection-policy.details',
      globalContext: true,
    },
  },

  getStateAccessMap(): StateAccessMap {
    return {
      'protection-policy': policyViewAccess,
      'protection-policy.policies': policyViewAccess,
      'protection-policy.build': policyModifyAccess,
      'protection-policy.details': policyViewAccess,
    };
  }
};
