/**
 * Provides lat/lon coordinates for AWS regions so they can be mapped.
 * NOTE: To get the list of region coordinates, go to Azure console and
 * use `az account list-locations` command
 */
export const regionCoordinates = {
  'us-east-1': {
    // N. Virginia
    lat: 37.5246403,
    lon: -77.5633001,
  },
  'us-east-2': {
    // Ohio
    lat: 40.3600809,
    lon: -83.7901827,
  },
  'us-gov-east-1': {
    // N. Virginia - Washington
    lat: 38.6278979,
    lon: -77.4080516,
  },
  'us-gov-west-1': {
    // Los Angeles - San Jose
    lat: 36.4476838,
    lon: -121.1957475,
  },
  'us-west-1': {
    // N. California
    lat: 38.880968,
    lon: -122.3076825,
  },
  'us-west-2': {
    // Oregon
    lat: 44.1274586,
    lon: -122.8256644,
  },
  'af-south-1': {
    // Cape Town
    lat: -33.914651,
    lon: 18.3758888,
  },
  'ap-east-1': {
    // Hong Kong
    lat: 22.3529808,
    lon: 113.9876181,
  },
  'ap-south-1': {
    // Mumbai
    lat: 19.0823998,
    lon: 72.8111467,
  },
  'ap-northeast-3': {
    // Asia Pacific (Osaka-Local)
    lat: 34.6777645,
    lon: 135.4160253,
  },
  'ap-northeast-2': {
    // Asia Pacific (Seoul)
    lat: 37.5640455,
    lon: 126.8340051,
  },
  'ap-southeast-1': {
    // Asia Pacific (Singapore)
    lat: 1.3143394,
    lon: 103.7041679,
  },
  'ap-southeast-2': {
    // Asia Pacific (Sydney)
    lat: -33.8478796,
    lon: 150.7918943,
  },
  'ap-northeast-1': {
    // Asia Pacific (Tokyo)
    lat: 35.5090628,
    lon: 139.2094193,
  },
  'ca-central-1': {
    // Canada (Central/Quebec)
    lat: 45.5581968,
    lon: -73.8703831,
  },
  'centralus': {
    // Azure Central US
    lat: 42.711375373433846,
    lon: -93.59030993332863,
  },
  'eu-central-1': {
    // Europe (Frankfurt)
    lat: 50.121301,
    lon: 8.5665248,
  },
  'eu-west-1': {
    // Europe (Ireland)
    lat: 53.3656751,
    lon: -10.4595662,
  },
  'eu-west-2': {
    // Europe (London)
    lat: 51.5287718,
    lon: -0.2416788,
  },
  'eu-south-1': {
    // Europe
    lat: 45.4628328,
    lon: 9.1076932,
  },
  'eu-west-3': {
    // Europe (Paris)
    lat: 48.8589507,
    lon: 2.2770209,
  },
  'eu-north-1': {
    // Europe (Stockholm)
    lat: 59.326242,
    lon: 17.841973,
  },
  'me-central-1': {
    // Middle East (UAE)
    lat: 23.424076,
    lon: 53.847816,
  },
  'me-south-1': {
    // Middle East
    lat: 25.941298,
    lon: 50.3074001,
  },
  'sa-east-1': {
    // South America (São Paulo)
    lat: -23.682035,
    lon: -46.7353804,
  },
  'azure-centralus': {
    // Iowa, US
    lat: 41.5908,
    lon: -93.6208,
  },
  'azure-eastus': {
    // Virginia, US
    lat: 37.3719,
    lon: -79.8164,
  },
  'azure-westus2': {
    // Washington, US
    lat: 47.233,
    lon: -119.852,
  },
  'azure-canadacentral': {
    // Toronto, ON
    lat: 43.6532,
    lon: -79.3832,
  },
  'azure-francecentral': {
    // Paris, France
    lat: 48.8566,
    lon: 2.3522,
  },
  'azure-australiaeast': {
    // New South Wales, Australia
    lat: -33.86,
    lon: 151.2094,
  },
  'azure-uksouth': {
    // UK South, Europe
    lat: 50.941,
    lon: -0.799,
  },
  'azure-centralindia': {
    // Pune
    lat: 18.5822,
    lon: 73.9197,
  },
  'azure-southeastasia': {
    // Singapore
    lat: 1.283,
    lon: 103.833,
  },
  'azure-eastus2': {
    // Virginia
    lat: 36.6681,
    lon: -78.3889,
  }
};
