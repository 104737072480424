import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { SourceSelection, SourceTreeFilters, TagCategory } from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';
import { UIRouterGlobals } from '@uirouter/core';
import { BehaviorSubject } from 'rxjs';
import { AzureBackupType, AzureUseCaseType, CloudJobType } from 'src/app/shared/constants';
import {
  AzureProtectionSourceDetailComponent
} from 'src/app/shared/source-tree/protection-source/azure/azure-protection-source-detail/azure-protection-source-detail.component';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { AzureSourceDataNode } from './azure-source-data-node';
import { AzureDiskExclusionOptionsComponent } from './disk-exclusion-options/azure-disk-exclusion-options.component';
import { AzureViewFilters } from './azure-view-filters';


type AzureTreeSelection = DataTreeSelection<AzureSourceDataNode>;

/**
 * Tree service for azure
 */
@Injectable({
  providedIn: 'root',
})
export class AzureSourceTreeService extends BaseProtectionSourceService<AzureSourceDataNode> {
  /**
   * A reference to azure view filters, to show a physical, flat list view.
   */
  azureViewFilters: AzureViewFilters<AzureSourceDataNode>;

  /**
   * Cloud Job Type.
   */
  private jobType$ = new BehaviorSubject<CloudJobType | ''>('');

  /**
   * The component to render for the tree's details.
   */
  detailComponents: ComponentType<any>[] = [AzureProtectionSourceDetailComponent];

  /**
   * Set the value for jobType Observable
   */
  set jobType(jobType: CloudJobType) {
    this.jobType$.next(jobType);

    if (jobType) {
      this.azureViewFilters.updateFlatFilterLabel(jobType);

      if ([CloudJobType.kAgent, CloudJobType.kAzureEntraID].includes(jobType)) {
        this.azureViewFilters.removeTagFilter();
      } else {
        this.azureViewFilters.addTagFilter();
      }
    }
  }

  /**
   * Returns the non-synchronous state of the treeService job type
   */
  get jobType(): CloudJobType {
    return this.jobType$.value || null;
  }

  constructor(
    readonly translate: TranslateService,
    private irisCtxService: IrisContextService,
    private uiRouterGlobals: UIRouterGlobals,
  ) {
    super();
    this.azureViewFilters = new AzureViewFilters(
      this.filters,
      this.treeControl,
      this.treeTransformer,
      this.irisCtxService,
      this.uiRouterGlobals.params
    );
  }

  /**
   * Parse a list of tree nodes to determine available tags that can be filtered by.
   *
   * @param   allNodes   The complete list of nodes.
   * @param   selection  The current selection.
   * @returns A list of tag categories that can be filtered by.
   */
  getTagCategories(
    allNodes: AzureSourceDataNode[],
    selection: DataTreeSelection<AzureSourceDataNode>
  ): TagCategory[] {
    const tagNodes = allNodes.filter(node => node.envSource.type === 'kTag');
    const tagMap: any = {};
    const tagGroups: any[] = [];
    const tags: TagCategory[] = [];

    // All available tags, grouped by category
    tagNodes.forEach(tagNode => {
      tagMap[tagNode.protectionSource.id] = tagNode.protectionSource.name;
      tagGroups.push([{
        id: tagNode.protectionSource.id,
        name: tagNode.protectionSource.name,
      }]);
    });

    tags.push({
      name: this.translate.instant('tags'),
      tagGroups: tagGroups,
    });

    // Add a category for currently excluded tags
    const excludedTags = (selection.excluded || []).filter(node => node.type === 'kTag');
    if (excludedTags.length) {
      tags.unshift({
        name: this.translate.instant('excluded'),
        tagGroups: excludedTags.map(tagNode =>
          tagNode.ids.map(tagId => ({
            id: Number(tagId),
            name: tagMap[tagId],
          }))
        ),
      });
    }

    // Add a category for currently auto protected tags
    const autoProtectedTags = (selection.autoSelected || []).filter(node => node.type === 'kTag');
    if (autoProtectedTags.length) {
      tags.unshift({
        name: this.translate.instant('autoProtected'),
        tagGroups: autoProtectedTags.map(tagNode =>
          tagNode.ids.map(tagId => ({
            id: Number(tagId),
            name: tagMap[tagId],
          }))
        ),
      });
    }

    return tags;
  }

  /**
   * Returns whether a node is a leaf or not. This can be used to calculate selection totals.
   */
  isLeaf(treeNode: AzureSourceDataNode): boolean {
    return treeNode.isLeaf;
  }

  /**
   * Override the unwrap root contains to remove unnecessary nodes for source tree.
   *
   * @param  tree   The list of nodes from the raw api response.
   * @returns   The unwrapped root container, with system databases grouped together.
   *
   */
  unwrapRootContainers(tree: ProtectionSourceNode[]): ProtectionSourceNode[] {
    const filteredTree = [];

    tree.forEach(node => {
      const nodeType = node.protectionSource.azureProtectionSource.type;
      const isLeaf = ['kVirtualMachine', 'kTag', 'kSQLDatabase', 'kSQLServer',
        'kSQLManagedInstance', 'kEntraID'].includes(nodeType);

      // '#~#' is a special identifier used by backend to separate key from
      // value. Retain name with api delimiter in 'azureProtectionSource.name' attribute
      // and replace it with a user-friendly string.
      if (node.protectionSource.azureProtectionSource.type === 'kTag') {
        node.protectionSource.azureProtectionSource.name = node.protectionSource.name;
        node.protectionSource.name = node.protectionSource.name.replace('#~#', ': ');
      }

      if (!['kSubscription', 'kResourceGroup', 'kVirtualMachine', 'kEntraID', 'kTag', 'kTenant', 'kSQLServer', 'kSQLDatabase',
        'kSQLManagedInstance'].includes(node.protectionSource.azureProtectionSource.type)) {
        return;
      }

      if (node.nodes && node.nodes.length) {
        node.nodes = this.unwrapRootContainers(node.nodes);
      }

      // accept a node if it is a valid leaf, or has valid children
      if (isLeaf || (node.nodes && node.nodes.length)) {

        filteredTree.push(node);
      }
    });

    return super.unwrapRootContainers(filteredTree);
  }

  /**
   * Only expand certain node types by default.
   *
   * @param   treeNode   The treenode to check.
   * @return  True if the node should be expanded by default.
   */
  shouldExpandNodeOnLoad(treeNode: AzureSourceDataNode): boolean {
    return ['kTenant', 'kSubscription', 'kResourceGroup'].includes(treeNode.type);
  }

  /**
   * Transforms the node object from the api into a Protection Source Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  An AzureSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): AzureSourceDataNode {
    const azureSourceDataNode =  new AzureSourceDataNode(node, level);
    let workload = this.uiRouterGlobals.params?.workload ?? this.uiRouterGlobals.params?.workloadType;

    // For tree context in quick protectMode, get transitionParams to set workload data
    workload = workload ?? this.uiRouterGlobals.current?.params?.transitionParams?.workloadType;
    if (workload) {
      azureSourceDataNode.workloadType = workload;
      azureSourceDataNode.disableAutoProtection = isDmsScope(this.irisCtxService.irisContext) &&
        !flagEnabled(this.irisCtxService.irisContext, 'dmsAzureVmAutoProtection') &&
        workload === AzureUseCaseType.kVirtualMachine;
    }
    this.jobType$.subscribe(value => azureSourceDataNode.setJobType(value));

    return azureSourceDataNode;
  }

  /**
   * Convert the data tree selection model to the job selection model.
   *
   * @param   selection   The selection from the tree.
   * @return  The job selection info.
   */
  transformFromDataTreeSelection(selection: DataTreeSelection<AzureSourceDataNode>): SourceSelection {
    // Sources include explicitly selected leaf node, and auto selected items
    const autoSelected = selection.autoSelected.filter(item => !item.isTag);
    const sources = selection.selected.filter(item => item.isLeaf).concat(autoSelected);

    return {
      // non-tag excluded items
      excludeSourceIds: selection.excluded.filter(item => !item.isTag).map(item => Number(item.id)),

      // excluded tags. The ids for these need to be converted to number since the ids property splits
      // a string and leaves them as strings. This comes out to an array of arrays.
      excludeVmTagIds: selection.excluded
        .filter(item => item.isTag)
        .map(item => item.ids.map(id => Number(id))),

      // non-tag source ids
      sourceIds: sources.map(item => Number(item.id)),

      // Special source params
      sourceSpecialParameters: Object.values(selection.options || {}).filter(Boolean),

      // tag source ids, an array of arrays.
      vmTagIds: selection.autoSelected.filter(item => item.isTag).map(item => item.ids.map(id => Number(id))),
    };
  }

  /**
   * Convert source selection to the data tree selection model.
   * source ids can be either selected items or auto selected items, nodes with children are
   * assumed to be auto selected. Nodes can be in the tree multiple times, and should not be
   * duplicated in the selection.
   *
   * @param   allNodes         The unfiltered list of tree nodes.
   * @param   sourceSelection  The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(
    allNodes: AzureSourceDataNode[],
    sourceSelection: SourceSelection
  ): AzureTreeSelection {
    const sourceMap = {};
    const selection: AzureTreeSelection = {
      autoSelected: [],
      excluded: [],
      selected: [],
      options: {},
    };

    if (sourceSelection) {
      const nodesLength = allNodes.length;
      for (let i = 0; i < nodesLength; i++) {
        const node = allNodes[i];

        if ((sourceSelection.sourceIds || []).includes(Number(node.id)) && !sourceMap[node.id]) {
          sourceMap[node.id] = node;
          const canAutoSelect = node.workloadType === 'kSQLDatabase' ? node.canAutoSelect() &&
            !['kVirtualMachine', 'kEntraID'].includes(node.type) :
            node.canAutoSelect();
          if (canAutoSelect) {
            selection.autoSelected.push(node);
          } else {
            selection.selected.push(node);
          }
        } else if ((sourceSelection.excludeSourceIds || []).includes(Number(node.id)) && !sourceMap[node.id]) {
          sourceMap[node.id] = node;
          selection.excluded.push(node);
        }
      }

      if (sourceSelection.sourceSpecialParameters) {
        selection.options = {};
        sourceSelection.sourceSpecialParameters.forEach(params => {
          selection.options[params.sourceId] = params;
        });
      }
    }

    if (sourceSelection.vmTagIds) {
      sourceSelection.vmTagIds.forEach(tagIds =>
        selection.autoSelected.push(this.azureViewFilters.createTagNode(tagIds, allNodes))
      );
    }

    if (sourceSelection.excludeVmTagIds) {
      sourceSelection.excludeVmTagIds.forEach(tagIds =>
        selection.excluded.push(this.azureViewFilters.createTagNode(tagIds, allNodes))
      );
    }
    return selection;
  }

  /**
   * Gets a component to render for a source's special parameters. This does not apply to every node
   * and can be null for certain data types.
   */
  getSpecialParametersComponent(node: AzureSourceDataNode): ComponentType<any> {
    if (
      isDmsScope(this.irisCtxService.irisContext) &&
      flagEnabled(this.irisCtxService.irisContext, 'azureVolumeExclusion') &&
        node.type === 'kVirtualMachine' &&
          CloudJobType.kAgent !== (this.jobType$.value as CloudJobType)
    ) {
      return AzureDiskExclusionOptionsComponent;
    }
    return null;
  }

  /**
   * Re-initializes view filter defaults based on current environment workloadType
   *
   * @param     workloadType     The environment job type
   * */
  initViewFilterDefaults(workloadType: string): SourceTreeFilters<AzureSourceDataNode> {
    if (workloadType !== AzureBackupType.kAzureEntraID) {
      this.azureViewFilters.updateFlatFilterLabel(workloadType as AzureBackupType);
    }

    if (workloadType === AzureBackupType.kAzureEntraID) {
      this.azureViewFilters.removeTagFilter();
      this.azureViewFilters.removePhysicalViewFilter();
    } else {
      this.azureViewFilters.addTagFilter();
      this.azureViewFilters.addPhysicalViewFilter();
    }
    return this.filters;
  }

  /**
   * Attaches a filterChange listener to the component instance to apply context based changes
   * based on the updated filter value.
   *
   * @param workloadType     The environment job type
   */
  attachFilterValueChangeListener(workloadType: string) {
    const workload = workloadType || this.uiRouterGlobals.params.workload || this.uiRouterGlobals.params.workloadType;
    this.initViewFilterDefaults(workload);
  }
}
