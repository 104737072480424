/* eslint-disable no-extra-boolean-cast */
import { UniversalId } from '@cohesity/api/v1';
import {
  ArchivalRunSummary,
  ArchivalTargetResult,
  BackupRunSummary,
  CloudSpinRunSummary,
  CloudSpinTargetResult,
  GetProtectionRunProgressBody,
  ProtectionGroupRun,
  ReplicationRunSummary,
  ReplicationTargetResult,
} from '@cohesity/api/v2';
import { decorateInterface } from '@cohesity/utils';
import { StateParams } from '@uirouter/core';
import { Environment, JobRunType, StatusObject, StatusType } from 'src/app/shared';
import { isNoSqlHadoopSource, isRelationalDatabase } from 'src/app/util';

import { ArchivalTarget } from './archival-target.models';
import { IProtectionRun } from './common.models';
import { ProtectionStates } from './protection-group.constants';
import { ProtectionRunObject } from './protection-run-object.models';
import { ProtectionRunReplication } from './protection-run-replication.models';

/**
 * Data structure for protection group run.
 */
export class ProtectionRun extends decorateInterface<IProtectionRun>() {

  /**
   * Run ID from V1 API.
   */
  v1RunId?: number;

  /**
   * Run ID from V2 API.
   */
  runId?: string;

  /**
   * Protection group instance ID.
   */
  protectionGroupInstanceId: number;

  /**
   * Job ID from V1 API.
   */
  jobId?: number;

  /**
   * Protection run objects.
   */
  objects?: ProtectionRunObject[];

  /**
   * Summary information about archival run.
   */
  archivalInfo?: ArchivalRunSummary;

  /**
   * Summary information about cloud spin run.
   */
  cloudSpinInfo?: CloudSpinRunSummary;

  /**
   * Summary information about replication run across all objects.
   */
  replicationInfo?: ReplicationRunSummary;

  /**
   * Map protection run object to their child objects -
   * objects that contain sourceId and sourceId matches object id in `objects` array.
   */
  readonly objectsTree = new WeakMap<ProtectionRunObject, ProtectionRunObject[]>();

  /**
   * Map protection run child object to its parent -
   * objects that contain sourceId and sourceId matches object id in `objects` array.
   */
  readonly childObjects = new WeakMap<ProtectionRunObject, ProtectionRunObject>();

  /**
   * Maps Archival Target ArchivalTarget instance StatusObject instance.
   */
  readonly archivalTargetStats = new WeakMap<ArchivalTarget, StatusObject>();

  /**
   * Progress monitor task for indexing.
   */
  indexingTaskId: string;

  /**
   * Backup messages
   */
  messages: string[];

  /**
   * Universal id for V1 Protection Job.
   */
  jobUid: UniversalId;

  /**
   * Task ID for a local protection run.
   */
  localTaskId: string;

  /**
   * Return true if any of the targets is on legalHold.
   */
  hasLegalHoldTargets = false;

  /**
   * Specifies if the environment if Office365.
   */
  get isOffice365Workload(): boolean {
    return this.environment === Environment.kO365;
  }

  /**
   * Indicates if run is in progress.
   */
  get isInProgress(): boolean {
    const runningStatus: StatusType[] = ['Running', 'Accepted'];
    if (this.isDirectArchive) {
      return runningStatus.includes(this.archivalStats?.[0]?.status);
    }

    return runningStatus.includes(this.backupStatus?.status);
  }

  /**
   * Returns default router state for this run instance.
   */
  get defaultState(): string {
    if (this.isDirectArchive) {
      return this.archivalStats?.[0]?.state;
    }

    return this.backupStatus?.state;
  }

  /**
   * Returns default router state params for this run instance.
   */
  get defaultStateParams(): StateParams {
    if (this.isDirectArchive) {
      return this.archivalStats?.[0]?.stateParams;
    }

    return this.backupStatus?.stateParams;
  }

  /**
   * Local backup or CAD status.
   */
  get status(): StatusType {
    if (this.isDirectArchive) {
      return this.archivalTargets?.[0]?.status;
    }

    return this.backupStatus?.status;
  }

  /**
   * Maps archival target ID to archival target.
   */
  archivalTargetById: Record<string, ArchivalTarget>;

  /**
   * Maps archival target ID to archival target.
   */
  replicationStatById: Record<string, StatusObject>;

  /**
   * Maps archival target ID to archival target.
   */
  replicationById: Record<string, ProtectionRunReplication>;

  /**
   * Maps object ID to protected object.
   */
  objectById: Record<number, ProtectionRunObject>;

  /**
   * Returns true if run is CDP Hydrate run.
   */
  get isCdpHydrate(): boolean {
    return this.runType === JobRunType.kHydrateCDP;
  }

  /**
   * Returns true if run is log run.
   */
  get isLogRun(): boolean {
    return this.runType === JobRunType.kLog;
  }

  /**
   * Return total number objects managed by this protection group.
   */
  get numObjects(): number {
    return this.objects?.length;
  }

  /**
   * Returns true if there are any failed objects in the last run.
   */
  get hasFailedObjects(): boolean {
    return this.objects?.some(object => [ 'Failed', 'Missed', 'kFailed' ].includes(object.status));
  }

  /**
   * Returns true if there are any successful objects in the last run.
   */
  get hasSuccessfulObjects(): boolean {
    return this.objects?.some(
      object => [ 'Succeeded', 'SucceededWithWarning', 'kSuccessful' ].includes(object.status)
    );
  }

  /**
   * Checks if run has cloud vault targets.
   */
  get hasCloudVaultTargets(): boolean {
    return this.archivalTargets?.some(target => target.isRpaas) ?? false;
  }

  /**
   * ProtectionRun constructor.
   *
   * @param  reference  V2 API protection run object.
   * @param  useStates  True if run state icons show be clickable to navigate to run pages.
   */
  constructor(readonly reference: ProtectionGroupRun, useStates?: boolean) {
    super({});

    this.transform(reference);

    if (useStates) {
      this.useStates();
    }
  }

  /**
   * Sets ui router state on run status icons to be clickable to navigate to run pages.
   */
  useStates() {
    if (this.backupStatus) {
      this.backupStatus.state = ProtectionStates.runBackup;
    }

    this.replicationStats?.forEach(stat => (stat.state = ProtectionStates.runReplication));

    this.vaultStats?.forEach(stat => (stat.state = ProtectionStates.runVaultCluster));

    this.archivalStats?.forEach(stat => {
      if (this.isDirectArchive) {
        stat.state = ProtectionStates.runDirectArchive;
      } else if (stat.type === 'tape') {
        stat.state = ProtectionStates.runTape;
      } else if (stat.type === 'vault') {
        stat.state = ProtectionStates.runCloudVault;
      } else {
        stat.state = ProtectionStates.runArchive;
      }
    });

    this.cloudSpinStats?.forEach(stat => (stat.state = ProtectionStates.runCloudspin));
  }

  /**
   * Parses `ProtectionJobRun` object to this instance of `ProtectionRun`.
   */
  private transform(protectionGroupRun: ProtectionGroupRun) {
    const {
      archivalInfo,
      cloudSpinInfo,
      environment,
      externallyTriggeredBackupTag,
      hasLocalSnapshot,
      isCloudArchivalDirect,
      isLocalSnapshotsDeleted = false,
      isReplicationRun = false,
      localBackupInfo,
      objects,
      onLegalHold = false,
      originalBackupInfo,
      originClusterIdentifier,
      originClusterIdentifier: { clusterName = '' } = {},
      originProtectionGroupId,
      permissions = [],
      protectionGroupId = '',
      protectionGroupInstanceId,
      protectionGroupName = '',
      replicationInfo,
    }: ProtectionGroupRun = protectionGroupRun;

    // in case of replication run, parse original cluster JobUid
    if (originProtectionGroupId) {
      const [
        originalClusterId,
        originalClusterIncarnationId,
        originalJobId
      ] = originProtectionGroupId.split(':').map(Number);

      this.originalJobUid = {
        clusterId: originalClusterId,
        clusterIncarnationId: originalClusterIncarnationId,
        id: originalJobId,
      };
    }

    const [
      clusterId,
      clusterIncarnationId,
      jobId
    ] = protectionGroupId.split(':').map(Number);

    this.jobUid = { clusterId, clusterIncarnationId, id: jobId };

    this.archivalInfo = archivalInfo;
    this.cloudSpinInfo = cloudSpinInfo;
    this.replicationInfo = replicationInfo;
    this.groupId = protectionGroupId;
    this.runId = this.getRunId(protectionGroupRun.id, this.groupId);
    this.v1RunId = this.runId && +this.runId.split(':').shift();
    this.clusterId = +clusterId;
    this.clusterIncarnationId = +clusterIncarnationId;
    this.jobId = +jobId;
    this.name = protectionGroupName;
    this.isLocalSnapshotsDeleted = isLocalSnapshotsDeleted;
    this.isReplicationRun = isReplicationRun;
    this.onLegalHold = onLegalHold;
    this.permissions = permissions;
    this.environment = environment as Environment;
    this.protectionGroupInstanceId = protectionGroupInstanceId;
    this.externallyTriggeredBackupTag = externallyTriggeredBackupTag;
    this.hasLocalSnapshot = hasLocalSnapshot;
    this.isDbRun = false;
    this.objects = (objects || []).map(obj => new ProtectionRunObject(obj, isCloudArchivalDirect)).filter(
      // Do not populate AD domain controller or Exchange databases objects.
      // TODO (Tung): remove when API returns correct source id hierarchy for AD, Exchange.
      runObj => ![Environment.kAD, Environment.kExchange].includes(runObj.environment)
    );

    if (this.objects.length > 0) {
      this.objectById = {};

      // populate objects map - objects by id
      this.objects.forEach(obj => (this.objectById[obj.id] = obj));

      // populate objects tree - child objects that are mapped to their parent object
      this.objects.forEach((obj: ProtectionRunObject) => {
        const { id, sourceId } = obj;
        const parent = this.objectById[sourceId] || this.objectById[id];

        if (parent && !this.objectsTree.has(parent)) {
          this.objectsTree.set(parent, []);
        }

        // The parent and child mappings need to be skipped for NoSQL, Hadoop & UDA sources.
        // The protection for these sources is done by a single slave task and only the cluster
        // level object is indexed as part of this. The sourceId field for these cluster
        // objects contains the id of the cluster itself and allowing these mappings will result
        // in duplicate entries showing up in the run-object-table component.
        if (!isNoSqlHadoopSource(this.environment) && this.environment !== Environment.kUDA) {
          if (Object.prototype.hasOwnProperty.call(this.objectById, sourceId)) {
            this.objectsTree.get(parent).push(obj);
            this.childObjects.set(obj, parent);
          }
        }
      });
    }

    this.isDbRun = isRelationalDatabase(this.environment);

    if (archivalInfo?.archivalTargetResults?.length) {
      this.archivalStats = [];
      this.archivalTargetById = {};
      this.archivalTargets = archivalInfo.archivalTargetResults.map((ar: ArchivalTargetResult) => {
        const archivalTarget = new ArchivalTarget(ar, !!originClusterIdentifier);
        this.archivalTargetById[archivalTarget.id] = archivalTarget;
        const { statusObject } = archivalTarget;
        statusObject.stateParams = {
          groupId: this.groupId,
          runId: this.runId,
          id: this.jobId,
          instanceId: this.protectionGroupInstanceId,
        },
        this.archivalTargetStats.set(archivalTarget, statusObject);
        this.archivalStats.push(statusObject);

        return archivalTarget;
      });
    }

    if (isCloudArchivalDirect) {
      this.isDirectArchive = isCloudArchivalDirect;

      if (this.archivalTargets?.length) {
        const [{
          bytesRead,
          cancelledAppObjectsCount,
          cancelledObjectsCount,
          durationMs: duration,
          endTimeUsecs,
          failedAppObjectsCount,
          failedObjectsCount,
          indexingTaskId,
          isDeleted,
          logicalTransferred,
          progressTaskId,
          queuedTimeUsecs,
          runType,
          size,
          slaStatus,
          startTimeUsecs,
          successfulAppObjectsCount,
          successfulObjectsCount,
        }] = this.archivalTargets;

        this.cancelledAppObjectsCount = cancelledAppObjectsCount;
        this.cancelledObjectsCount = cancelledObjectsCount;
        this.failedAppObjectsCount = failedAppObjectsCount;
        this.failedObjectsCount = failedObjectsCount;
        this.successfulAppObjectsCount = successfulAppObjectsCount;
        this.successfulObjectsCount = successfulObjectsCount;
        this.slaStatus = slaStatus;
        this.runType = runType;
        this.startTimeUsecs = startTimeUsecs;
        this.endTimeUsecs = endTimeUsecs;
        this.startDate = new Date((startTimeUsecs || queuedTimeUsecs) / 1000);
        this.endDate = new Date(endTimeUsecs / 1000);
        this.duration = duration;
        this.totalBytes = size;
        this.readBytes = bytesRead;
        this.writeBytes = logicalTransferred;
        this.progressTaskId = progressTaskId;
        this.indexingTaskId = indexingTaskId;

        this.isLocalSnapshotsDeleted = isDeleted;

        this.totalObjectsCount = successfulObjectsCount + failedObjectsCount + cancelledObjectsCount;
        this.totalAppObjectsCount = successfulAppObjectsCount + failedAppObjectsCount + cancelledAppObjectsCount;
      }
    } else {
      const backupSummary: BackupRunSummary = originalBackupInfo || localBackupInfo || {};

      const {
        cancelledAppObjectsCount = 0,
        cancelledObjectsCount = 0,
        dataLockConstraints,
        endTimeUsecs,
        failedAppObjectsCount = 0,
        failedObjectsCount = 0,
        skippedObjectsCount = 0,
        indexingTaskId,
        isSlaViolated,
        localSnapshotStats: {
          bytesWritten = 0,
          logicalSizeBytes = 0,
          bytesRead = 0
        } = {},
        localTaskId,
        messages,
        progressTaskId,
        runType,
        startTimeUsecs,
        status: backupStatus,
        successfulAppObjectsCount = 0,
        successfulObjectsCount = 0,
        pauseMetadata: {
          pausedNote,
          lastPausedByUsername,
          userInitiatedPauseRequestedTimeUsecs,
        } = {}
      }: BackupRunSummary = backupSummary;

      const startDate = new Date(startTimeUsecs / 1000);
      const endDate = new Date(endTimeUsecs / 1000);

      this.startDate = startDate;
      this.endDate = endDate;
      this.startTimeUsecs = startTimeUsecs;
      this.endTimeUsecs = endTimeUsecs;
      this.duration = Math.max(0, endTimeUsecs - startTimeUsecs) / 1000;
      this.totalBytes = logicalSizeBytes;
      this.readBytes = bytesRead;
      this.writeBytes = bytesWritten;
      this.isSlaViolated = isSlaViolated;
      this.successfulObjectsCount = successfulObjectsCount;
      this.successfulAppObjectsCount = successfulAppObjectsCount;
      this.cancelledAppObjectsCount = cancelledAppObjectsCount;
      this.cancelledObjectsCount = cancelledObjectsCount;
      this.failedObjectsCount = failedObjectsCount;
      this.skippedObjectsCount = skippedObjectsCount;
      this.failedAppObjectsCount = failedAppObjectsCount;
      this.dataLockConstraints = dataLockConstraints;
      this.messages = messages;
      this.pausedNote = pausedNote;
      this.lastPausedByUsername = lastPausedByUsername;
      this.userInitiatedPauseRequestedTimeUsecs = userInitiatedPauseRequestedTimeUsecs;
      this.progressTaskId = progressTaskId;
      this.indexingTaskId = indexingTaskId;
      this.runType = runType;
      this.localTaskId = localTaskId;

      // if isSlaViolated is undefined or null, then SLA status is empty/undefined.
      if (typeof isSlaViolated === 'boolean') {
        this.slaStatus = this.createStat(isSlaViolated ? 'kWarning' : 'kSuccess', 'sla');
      }

      this.backupStatus = this.createStat(backupStatus, 'cluster', this.getBackupStatusMessage());
      this.totalObjectsCount = successfulObjectsCount + failedObjectsCount +
        cancelledObjectsCount + skippedObjectsCount;
      this.totalAppObjectsCount = successfulAppObjectsCount + failedAppObjectsCount + cancelledAppObjectsCount;

      if (isReplicationRun && hasLocalSnapshot) {
        this.replicationStats = [this.createStat(backupStatus, 'replication', clusterName)];
      }
    }

    // TODO
    if (replicationInfo?.replicationTargetResults?.length) {
      const cloudReplicationConfig =
          replicationInfo.replicationTargetResults[0].awsTargetConfig ||
          replicationInfo.replicationTargetResults[0].azureTargetConfig;

      this.vaultStats = [];
      this.vaultTargets = [];
      this.replicationStats = [];
      this.replicationTargets = [];
      this.replicationStatById = {};
      this.replicationById = {};
      replicationInfo.replicationTargetResults.forEach((rr: ReplicationTargetResult) => {
        const name = !!cloudReplicationConfig ?
          cloudReplicationConfig.name : rr.message || rr.clusterName;

        if (rr.ownershipContext !== 'FortKnoxOnprem') {
          const replicationStat = this.createStat(rr.status, 'replication', name, rr.clusterId);
          this.replicationStats.push(replicationStat);
          this.replicationStatById[rr.clusterId] = replicationStat;
          const replication = new ProtectionRunReplication(rr, this.environment);
          this.replicationById[rr.clusterId] = replication;
          this.replicationTargets.push(replication);
        } else if (rr.ownershipContext === 'FortKnoxOnprem') {
          this.vaultStats.push(this.createStat(rr.status, 'vault', name, rr.clusterId));
          this.vaultTargets.push(new ProtectionRunReplication(rr, this.environment));
        }
      });

      if (isReplicationRun) {
        const replicationMessages = replicationInfo.replicationTargetResults
          .filter(({ message }) => message)
          .map(({ message }) => message);

        this.messages = [ ...(this.messages || []), ...replicationMessages ];
      }
    }

    // Aggregate messages for archival target - Cloud archive direct
    if (isCloudArchivalDirect && archivalInfo?.archivalTargetResults?.length) {
      const archivalMessages = archivalInfo.archivalTargetResults
        .filter(({ message }) => message)
        .map(({ message }) => message);

      if (archivalMessages.length) {
        this.messages = [ ...(this.messages || []), ...archivalMessages ];
      }
    }

    if (cloudSpinInfo?.cloudSpinTargetResults) {
      this.cloudSpinStats = cloudSpinInfo.cloudSpinTargetResults.map((cs: CloudSpinTargetResult) =>
        this.createStat(cs.status, 'cloud-spin', cs.name, cs.id)
      );
    }

    if (this.isOffice365Workload) {
      // Fallback to show data written as data read incase of O365 workloads cannot be determined currently.
      // TODO(tauseef): Remove this once data is correct.
      this.writeBytes = this.readBytes;
    }

    // Check every target for legal hold value
    this.hasLegalHoldTargets = [
      ...(cloudSpinInfo?.cloudSpinTargetResults ?? []),
      ...(this.archivalTargets ?? []),
      ...(this.replicationTargets ?? [])
    ].some(target => target.onLegalHold);
  }

  /**
   * Returns the right runId. i.e. if local run, return the id
   * else, get the jobId from the groupId and pair that with the
   * start time of the protection group run.
   *
   * @param id runId of the protection group run
   * @param groupId protection group Id
   */
  private getRunId(id: string, groupId: string): string {
    const splitGroupId = groupId?.split(':');
    const splitRunId = id?.split(':');

    if (splitRunId?.length && splitGroupId?.length &&
        splitRunId[0] !== splitGroupId[2]) {
      // this is a run belonging to a remote job. use the right runId.
      return `${splitGroupId[2]}:${splitRunId[1]}`;
    } else {
      return id;
    }
  }

  /**
   * Returns `StatusObject` based on params provided.
   *
   * @param  status  Object status.
   * @param  type    Type of `StatusObject` icon.
   * @param  name    Name label for `StatusObject`.
   * @param  state   Router state to navigate to on icon click.
   * @param  id      Object ID.
   */
  private createStat(status: StatusType, type: string, name?: string, id?: number): StatusObject {
    return {
      id,
      name,
      stateParams: {
        groupId: this.groupId,
        runId: this.runId,
        id: this.jobId,
        instanceId: this.protectionGroupInstanceId,
        startTimeUsecs: this.startTimeUsecs,
      },
      status,
      type,
    };
  }

  /**
   * Returns backup status message as formatted string.
   */
  getBackupStatusMessage(): string {
    return this.messages && this.messages.length ? this.messages.join('\n') : '';
  }

  /**
   * Set the backup status name.
   *
   * @param  name  Name of backup status.
   */
  setBackupStatusName = (name: string) => {
    if (this.backupStatus) {
      const message = this.getBackupStatusMessage();
      this.backupStatus.name = message.length ? `${name}\n${message}` : name;
    }
  };

  /**
   * Updates progress info for each object that is part of this run.
   *
   * @param  runProgress Protection run progress data.
   */
  updateProgress = (runProgress: GetProtectionRunProgressBody) => {
    // update local backup progress for each object.
    runProgress.localRun?.objects?.forEach(object => {
      const runObj = this.objectById?.[object.id];
      if (runObj?.backupProgress) {
        runObj.backupProgress.percentageCompleted = object.percentageCompleted;
        runObj.backupProgress.events = object.events;
      }
    });

    // update archival progress
    runProgress.archivalRun?.forEach(archiveProgress => {
      this.archivalTargetById[archiveProgress.targetId]?.updateProgress(runProgress);
      archiveProgress.objects?.forEach(object => {
        const runObj = this.objectById?.[object.id];
        if (runObj?.archivalProgress) {
          runObj.archivalProgress.percentageCompleted = object.percentageCompleted;
          runObj.archivalProgress.events = object.events;
        }
      });
    });

    // update replication progress
    runProgress.replicationRun?.forEach(replicationProgress => {
      this.replicationById[replicationProgress.clusterId]?.updateProgress(runProgress);
      replicationProgress.objects?.forEach(object => {
        const runObj = this.objectById?.[object.id];
        if (runObj?.replicationProgress) {
          runObj.replicationProgress.percentageCompleted = object.percentageCompleted;
          runObj.replicationProgress.events = object.events;
        }
      });
    });

    this.events = this.isDirectArchive ? this.archivalTargets?.[0]?.events : runProgress.localRun?.events;
    this.percentageCompleted = this.isDirectArchive ?
      this.archivalTargets?.[0]?.percentageCompleted : runProgress.localRun?.percentageCompleted;
  };

  /**
   * Return true if the run is cloud retrieve
   *
   * @param targetClusterId Cluster ID.
   * @returns True if the run is cloud retrieve.
   */
  isCloudRetrieveRun(targetClusterId: string): boolean {
    const clusterId = this?.archivalInfo?.archivalTargetResults[0]?.archivalTaskId?.split(':')[0];
    return !!clusterId && clusterId.toString() !== targetClusterId.toString();
  }

}
